import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  CheckCircleIcon,
  ChartBarIcon,
  ListBulletIcon,
  CurrencyEuroIcon,
  HomeIcon,
  IdentificationIcon,
  MapPinIcon
} from '@heroicons/react/24/solid';
import confetti from 'canvas-confetti';
import { formatPrice } from '../../utility/formatPrice';
import { remoteConfig } from '../../utility/firebaseRemoteConfigHelper';
import FeedbackCard from '../../components/feedback-card/FeedbackCard';
import { useAnalyticsWithConsent } from '../../hooks/useAnalyticsWithConsent';

interface ListingSummary {
  propertyId: string;
  title: string;
  price: number;
  location: string;
}

const SuccessPage: React.FC = () => {
  const location = useLocation();
  const listingSummary: ListingSummary = location.state?.listingSummary;
  const [animate, setAnimate] = useState(false);
    const analytics = useAnalyticsWithConsent();
  
    analytics.trackPixelPageView();

  useEffect(() => {
    // Trigger confetti animation
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });

    // Trigger entrance animation
    setAnimate(true);

    // Scroll to top
    window.scrollTo(0, 0);
  }, []);

  if (!listingSummary) {
    return <div>No listing information available.</div>;
  }

   return (
     <div className="min-h-screen flex items-center justify-center px-4 py-8 sm:px-6 lg:px-8">
       <div
         className={`bg-white shadow-2xl rounded-lg p-4 sm:p-8 text-center w-full max-w-md sm:max-w-2xl transform transition-all duration-500 ${
           animate ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
         }`}
       >
         <div className="mb-6 sm:mb-8">
           <CheckCircleIcon className="w-16 h-16 sm:w-20 sm:h-20 text-green-500 mx-auto animate-bounce" />
         </div>

         <h1 className="text-3xl sm:text-4xl font-extrabold text-gray-900 mb-3 sm:mb-4">
           Congratulations!
         </h1>

         <p className="text-lg sm:text-xl text-gray-600 mb-3 sm:mb-4 px-2 sm:px-4">
           Your property has been successfully submitted and will be evaluated
           to ensure it meets our listing guidelines before going live.
         </p>

         <p className="text-sm sm:text-md text-gray-500 mb-6 sm:mb-8">
           Once approved, it will be visible to potential tenants.
         </p>

         <div className="bg-gradient-to-r from-blue-50 to-purple-50 rounded-lg p-4 sm:p-6 mb-6 sm:mb-8 shadow-lg hover:shadow-xl transition-shadow duration-300 mx-auto max-w-lg">
           <h2 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6 text-gray-800 border-b pb-2">
             Listing Summary
           </h2>

           <div className="space-y-3 sm:space-y-4 text-left">
             <div className="flex items-start space-x-3 group px-2">
               <HomeIcon className="h-5 w-5 sm:h-6 sm:w-6 mt-1 flex-shrink-0 text-blue-500 group-hover:text-blue-600 transition-colors duration-200" />
               <div className="flex-1 min-w-0">
                 <p className="text-base sm:text-lg">
                   <span className="font-medium text-gray-700">Title:</span>{' '}
                   <span className="text-blue-600 group-hover:text-blue-700 transition-colors duration-200 break-words">
                     {listingSummary.title}
                   </span>
                 </p>
               </div>
             </div>

             <div className="flex items-start space-x-3 group px-2">
               <CurrencyEuroIcon className="h-5 w-5 sm:h-6 sm:w-6 mt-1 flex-shrink-0 text-green-500 group-hover:text-green-600 transition-colors duration-200" />
               <div className="flex-1 min-w-0">
                 <p className="text-base sm:text-lg">
                   <span className="font-medium text-gray-700">Price:</span>{' '}
                   <span className="text-green-600 font-bold group-hover:text-green-700 transition-colors duration-200">
                     €{formatPrice(listingSummary.price)}
                   </span>{' '}
                   per month
                 </p>
               </div>
             </div>

             <div className="flex items-start space-x-3 group px-2">
               <MapPinIcon className="h-5 w-5 sm:h-6 sm:w-6 mt-1 flex-shrink-0 text-purple-500 group-hover:text-purple-600 transition-colors duration-200" />
               <div className="flex-1 min-w-0">
                 <p className="text-base sm:text-lg">
                   <span className="font-medium text-gray-700">Location:</span>{' '}
                   <span className="text-purple-600 group-hover:text-purple-700 transition-colors duration-200 break-words">
                     {listingSummary.location}
                   </span>
                 </p>
               </div>
             </div>

             <div className="flex items-start space-x-3 group px-2">
               <IdentificationIcon className="h-5 w-5 sm:h-6 sm:w-6 mt-1 flex-shrink-0 text-gray-500 group-hover:text-gray-600 transition-colors duration-200" />
               <div className="flex-1 min-w-0">
                 <p className="text-base sm:text-lg">
                   <span className="font-medium text-gray-700">
                     Listing ID:
                   </span>{' '}
                   <span className="text-gray-600 group-hover:text-gray-700 transition-colors duration-200 break-all">
                     {listingSummary.propertyId}
                   </span>
                 </p>
               </div>
             </div>
           </div>
         </div>
         {remoteConfig.isFeedbackEnabled() && (
           <>
             <FeedbackCard
               variant="compact"
               title="Quick Feedback"
               subtitle="Tell us what you think"
             />
           </>
         )}
         <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4 justify-center px-2 sm:px-4 mt-4">
           <Link
             to="/dashboard"
             className="flex items-center justify-center px-4 sm:px-6 py-3 border border-transparent text-sm sm:text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transform transition duration-300 hover:scale-105 w-full sm:w-auto"
           >
             <ChartBarIcon className="w-5 h-5 mr-2" />
             Go to Dashboard
           </Link>

           <Link
             to="/my-listings"
             className="flex items-center justify-center px-4 sm:px-6 py-3 border border-transparent text-sm sm:text-base font-medium rounded-md text-blue-600 bg-blue-100 hover:bg-blue-200 transform transition duration-300 hover:scale-105 w-full sm:w-auto"
           >
             <ListBulletIcon className="w-5 h-5 mr-2" />
             Manage Listings
           </Link>
         </div>
       </div>
     </div>
   );
};

export default SuccessPage;
