import React, { startTransition } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/firebaseAuth';
import appLogo from '../../media/logo/logo_variant6.png';
import { useCookieConsent } from '../../context/CookieConsentContext';
import { FacebookIcon, TelegramIcon, WhatsappIcon } from 'react-share';

const Footer: React.FC = () => {
   const { showConsentForm } = useCookieConsent();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleLinkClick = (path: string) => {
    startTransition(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      // Place any asynchronous or suspending logic here, if necessary.
      // For example, if it triggers navigation or state updates.
      navigate(path); // Assuming you have a `navigate` function
    });
  };

  return (
    <footer className="bg-gray-800 text-white mt-12">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8 mb-8 border-b border-gray-700">
          <Link
            to="/"
            className="flex items-center"
            onClick={() => handleLinkClick('/')}
          >
            <img
              src={appLogo}
              alt="RentSmart.ie Logo"
              className="h-20 w-20 text-blue-500"
            />
            <span className="ml-3 text-2xl font-bold text-white">
              RentSmart.ie
            </span>
          </Link>
          <p className="mt-2 text-sm text-gray-300">
            Find your perfect home with ease. RentSmart.ie connects you with the
            best rental properties across Ireland.
          </p>
        </div>
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  For Renters
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      to="/browse"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/browse')}
                    >
                      Browse Listings
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/auto-apply"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/auto-apply')}
                    >
                      Experience auto apply
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/property-tools/property-alerts"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() =>
                        handleLinkClick('/property-tools/property-alerts')
                      }
                    >
                      Get property alerts to your inbox
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/property-tools"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/property-tools')}
                    >
                      Property Tools
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/bookmarks"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/bookmarks')}
                    >
                      Saved Listings
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/renter-guide"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/renter-guide')}
                    >
                      Renter's Guide
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/report-listing"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/report-listing')}
                    >
                      Report a listing
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  For Property Owners
                </h3>
                <ul className="mt-4 space-y-4">
                  {currentUser && (
                    <>
                      <li>
                        <Link
                          to="/add-listing"
                          className="text-base text-gray-300 hover:text-white"
                          onClick={() => handleLinkClick('/add-listing')}
                        >
                          List a Property
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/dashboard"
                          className="text-base text-gray-300 hover:text-white"
                          onClick={() => handleLinkClick('/dashboard')}
                        >
                          Listing Dashboard
                        </Link>
                      </li>
                    </>
                  )}
                  <li>
                    <Link
                      to="/property-tools"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/property-tools')}
                    >
                      Property Tools
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/landlord-guide"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/landlord-guide')}
                    >
                      Landlord's Guide
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      to="/about-us"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/about-us')}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/contact')}
                    >
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/safety"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/safety')}
                    >
                      Safety
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/help-center"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/help-center')}
                    >
                      Help center
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blog"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/blog')}
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/faqs"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/faqs')}
                    >
                      FAQs
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      to="/terms-of-service"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/terms-of-service')}
                    >
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacy-policy"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/privacy-policy')}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cookie-policy"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/cookie-policy')}
                    >
                      Cookie Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/data-requests"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/data-requests')}
                    >
                      Data requests
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cookie-preferences"
                      className="text-base text-gray-300 hover:text-white"
                      onClick={() => handleLinkClick('/cookie-preferences')}
                    >
                      Manage Preferences
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          {/* Social Icons */}
          <div className="flex space-x-6 md:order-2">
            {/* Facebook */}
            <a
              href="https://www.facebook.com/groups/1295007891494794"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">Facebook</span>
              <FacebookIcon size={32} round className="h-8 w-8 text-blue-600" />
            </a>

            {/* Telegram */}
            <a
              href="https://t.me/rentsmart_ie"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">Telegram</span>
              <TelegramIcon size={32} round className="h-8 w-8 text-blue-500" />
            </a>

            {/* WhatsApp */}
            <a
              href="https://chat.whatsapp.com/JQXsWjrrybP8covAaVggnD"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">WhatsApp</span>
              <WhatsappIcon
                size={32}
                round
                className="h-8 w-8 text-green-500"
              />
            </a>
          </div>

          {/* Footer Text */}
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-base text-gray-400">
              &copy; {new Date().getFullYear()} RentSmart.ie. All rights
              reserved.
            </p>
            <p className="mt-2 text-sm text-gray-300">
              Built with love in Ireland 🇮🇪❤️
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
