import React, { useState, useEffect, useRef } from 'react';
import {
  MagnifyingGlassIcon,
  AdjustmentsHorizontalIcon
} from '@heroicons/react/24/solid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { locations } from '../../data/locations';
import { SearchParams } from '../../pages/browse-listings/BrowseListingsPage';
import {
  ListingIntent,
  PropertyType
} from '../property/property-card/PropertyCardTypes';
import { X, History, Lock } from 'lucide-react';
import { useAnalyticsWithConsent } from '../../hooks/useAnalyticsWithConsent';

interface SearchBarProps {
  searchParams: SearchParams;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
  onSearch: (params: SearchParams) => void;
}

// Types for recent searches
interface RecentSearch {
  id: string;
  params: SearchParams;
  timestamp: number;
  summary: string; // Human readable summary
}

const propertyTypes: (PropertyType | 'Any')[] = [
  'Any',
  'Apartment',
  'House',
  'Studio',
  'Duplex',
  'Townhouse'
];

const berRatings = [
  'Any',
  'A1',
  'A2',
  'A3',
  'B1',
  'B2',
  'B3',
  'C1',
  'C2',
  'C3',
  'D1',
  'D2',
  'E1',
  'E2',
  'F',
  'G'
];

const listingIntents: (ListingIntent | 'Any')[] = [
  'Any',
  'rent',
  'sale',
  'share'
];

// Hook to manage recent searches
const useRecentSearches = (maxSearches: number = 5) => {
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>(() => {
    const saved = localStorage.getItem('recentSearches');
    return saved ? JSON.parse(saved) : [];
  });

  const addRecentSearch = (params: SearchParams) => {
    const summary = generateSearchSummary(params);
    const newSearch: RecentSearch = {
      id: crypto.randomUUID(),
      params,
      timestamp: Date.now(),
      summary
    };

    setRecentSearches((prev) => {
      // Remove exact duplicates
      const filtered = prev.filter(
        (search) => JSON.stringify(search.params) !== JSON.stringify(params)
      );

      // Add new search to beginning and limit to maxSearches
      const updated = [newSearch, ...filtered].slice(0, maxSearches);
      localStorage.setItem('recentSearches', JSON.stringify(updated));
      console.log('Added search: : ', updated);
      return updated;
    });
  };

  const removeRecentSearch = (id: string) => {
    setRecentSearches((prev) => {
      const updated = prev.filter((search) => search.id !== id);
      localStorage.setItem('recentSearches', JSON.stringify(updated));
      return updated;
    });
  };

  const clearRecentSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem('recentSearches');
  };

  return {
    recentSearches,
    addRecentSearch,
    removeRecentSearch,
    clearRecentSearches
  };
};

// Helper function to generate human-readable summary
const generateSearchSummary = (params: SearchParams): string => {
  const parts: string[] = [];

  if (params.location) {
    parts.push(params.location);
  }

  if (params.propertyType && params.propertyType !== 'Any') {
    parts.push(params.propertyType);
  }

  if (params.bedrooms > 0) {
    parts.push(`${params.bedrooms} bed`);
  }

  if (params.minPrice > 0 || params.maxPrice > 0) {
    if (params.minPrice > 0 && params.maxPrice > 0) {
      parts.push(`€${params.minPrice}-${params.maxPrice}`);
    } else if (params.minPrice > 0) {
      parts.push(`€${params.minPrice}+`);
    } else {
      parts.push(`Up to €${params.maxPrice}`);
    }
  }

  return parts.join(' · ') || 'All properties';
};

const SearchBar: React.FC<SearchBarProps> = ({
  searchParams,
  setSearchParams,
  onSearch
}) => {
  const [localParams, setLocalParams] = useState<SearchParams>(searchParams);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const analytics = useAnalyticsWithConsent();
  const {
    recentSearches,
    addRecentSearch,
    removeRecentSearch,
    clearRecentSearches
  } = useRecentSearches();

  useEffect(() => {
    setLocalParams(searchParams);
  }, [searchParams]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setLocalParams((prev) => ({ ...prev, [name]: value }));

    if (name === 'location') {
      // Hide recent searches when typing
      setShowRecentSearches(value.length === 0);

      // Handle location suggestions
      const filteredSuggestions =
        value.trim() === ''
          ? []
          : locations.filter((loc) =>
              loc.toLowerCase().startsWith(value.toLowerCase())
            );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(filteredSuggestions.length > 0);
    }
  };

  const handleInputFocus = () => {
    // Only show recent searches if the input is empty
    if (!localParams.location) {
      setShowRecentSearches(true);
    }
  };

  const handleInputBlur = (e: React.FocusEvent) => {
    // Check if the related target is within our search component
    const isRelatedTargetWithin =
      e.relatedTarget &&
      (e.relatedTarget as HTMLElement).closest('.search-container');

    if (!isRelatedTargetWithin) {
      // Add a small delay to allow for clicks on recent searches
      setTimeout(() => {
        setShowRecentSearches(false);
      }, 200);
    }
  };

  const handleNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLocalParams((prev) => ({
      ...prev,
      [name]: value === '' ? 0 : parseInt(value)
    }));
  };

  const getInputValue = (value: number) => {
    return value === 0 ? '' : value.toString();
  };

  const handleDateChange = (date: Date | null) => {
    setLocalParams((prev) => ({ ...prev, availableFrom: date }));
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchParams(localParams);
    onSearch(localParams);
    addRecentSearch(localParams);
    setShowSuggestions(false);
    setShowRecentSearches(false);
    analytics.trackPixelCustom('Search', localParams);
  };

  const handleSuggestionClick = (suggestion: string) => {
    const updatedParams = { ...localParams, location: suggestion };

    // Update search params state and add to recent searches after setting state
    setSearchParams(updatedParams);
    onSearch(updatedParams);

    // Directly add to recent searches after calling search function
    addRecentSearch(updatedParams);

    setLocalParams(updatedParams);
    setSuggestions([]);
    setShowSuggestions(false);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleApplyFilters = () => {
    setSearchParams(localParams);
    onSearch(localParams);
    setShowSuggestions(false);
    setIsAdvancedOpen(false);
  };
  const handleClearFilters = () => {
    const clearedParams: SearchParams = {
      ...localParams,
      minPrice: 0,
      maxPrice: 0,
      bedrooms: 0,
      bathrooms: 0,
      propertyType: 'Any',
      berRating: 'Any',
      availableFrom: null,
      listingIntent: 'Any',
      location: ''
    };
    setLocalParams(clearedParams);
    setSearchParams(clearedParams);
    onSearch(clearedParams);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };

    if (showSuggestions) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSuggestions]);

  // Add recent searches dropdown
  const renderRecentSearches = () => (
    <div className="absolute z-20 w-full bg-white border border-gray-200 rounded-lg shadow-lg mt-2 max-h-96 overflow-y-auto">
      <div className="p-3 border-b border-gray-200 flex justify-between items-center">
        <h5 className="font-medium text-gray-700 flex items-center">
          <History className="w-4 h-4 mr-2" />
          Recent Searches
        </h5>
        {recentSearches.length > 0 && (
          <button
            onClick={clearRecentSearches}
            className="text-sm text-gray-500 hover:text-gray-700"
          >
            Clear All
          </button>
        )}
      </div>

      {recentSearches.length === 0 ? (
        <div className="p-4 text-center text-gray-500">
          No recent searches - Start by typing a location
        </div>
      ) : (
        <div className="divide-y divide-gray-100">
          {recentSearches.map((search) => (
            <div
              key={search.id}
              className="p-3 hover:bg-gray-50 flex items-center justify-between group"
            >
              <button
                onClick={() => {
                  setLocalParams(search.params);
                  setSearchParams(search.params);
                  onSearch(search.params);
                  setShowRecentSearches(false);
                }}
                className="flex items-center space-x-3 flex-1"
              >
                <Lock className="w-4 h-4 text-gray-400" />
                <div className="flex-1 text-left">
                  <p className="text-sm font-medium text-gray-700">
                    {search.summary}
                  </p>
                  <p className="text-xs text-gray-500">
                    {new Date(search.timestamp).toLocaleDateString()}
                  </p>
                </div>
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  removeRecentSearch(search.id);
                }}
                className="p-1 opacity-0 group-hover:opacity-100 transition-opacity"
                aria-label="Remove search"
              >
                <X className="w-4 h-4 text-gray-400 hover:text-gray-600" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="w-full max-w-4xl mx-auto bg-white shadow-lg rounded-lg search-container">
      <form onSubmit={handleSearch} className="p-4 relative">
        <div className="flex items-center mb-4">
          <div className="relative flex-1">
            <div className="relative">
              <input
                type="text"
                name="location"
                value={localParams.location}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder="Search by location..."
                className="w-full h-10 px-4 rounded-l-lg border text-gray-800 border-gray-200 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                autoComplete="off"
                ref={inputRef}
                aria-autocomplete="list"
                aria-controls="location-suggestions"
              />
              {/* Recent Searches - only show when input is empty and focused */}
              {showRecentSearches &&
                !localParams.location &&
                renderRecentSearches()}
              {/* Location Suggestions - show when typing */}
              {showSuggestions && suggestions.length > 0 && (
                <ul
                  ref={suggestionsRef}
                  id="location-suggestions"
                  role="listbox"
                  className="absolute z-10 w-full bg-white border border-gray-200 rounded-b-lg max-h-60 overflow-y-auto shadow-lg"
                >
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                      role="option"
                      aria-selected={false}
                      className="px-4 py-2 hover:bg-blue-100 cursor-pointer"
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <button
            type="submit"
            className="h-10 px-4 bg-blue-500 text-white rounded-r-lg hover:bg-blue-600 transition duration-300 border border-blue-500"
            aria-label="Search"
          >
            <MagnifyingGlassIcon className="h-5 w-5" />
          </button>
        </div>
        <button
          type="button"
          onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
          className="flex items-center text-blue-500 hover:text-blue-600"
          aria-expanded={isAdvancedOpen}
          aria-controls="advanced-search"
        >
          <AdjustmentsHorizontalIcon className="h-5 w-5 mr-1" />
          {isAdvancedOpen ? 'Hide Advanced Search' : 'Show Advanced Search'}
        </button>
      </form>

      {isAdvancedOpen && (
        <div
          id="advanced-search"
          className="px-4 pb-4 space-y-4 border-t border-gray-200"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="minPrice"
                className="block text-sm font-medium text-gray-700"
              >
                Min Price (€)
              </label>
              <input
                type="number"
                id="minPrice"
                name="minPrice"
                value={getInputValue(localParams.minPrice)}
                onChange={handleNumberInput}
                min="0"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="maxPrice"
                className="block text-sm font-medium text-gray-700"
              >
                Max Price (€)
              </label>
              <input
                type="number"
                id="maxPrice"
                name="maxPrice"
                value={getInputValue(localParams.maxPrice)}
                onChange={handleNumberInput}
                min="0"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="bedrooms"
                className="block text-sm font-medium text-gray-700"
              >
                Bedrooms
              </label>
              <input
                type="number"
                id="bedrooms"
                name="bedrooms"
                value={getInputValue(localParams.bedrooms)}
                onChange={handleNumberInput}
                min="0"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="bathrooms"
                className="block text-sm font-medium text-gray-700"
              >
                Bathrooms
              </label>
              <input
                type="number"
                id="bathrooms"
                name="bathrooms"
                value={getInputValue(localParams.bathrooms)}
                onChange={handleNumberInput}
                min="0"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label
                htmlFor="propertyType"
                className="block text-sm font-medium text-gray-700"
              >
                Property Type
              </label>
              <select
                id="propertyType"
                name="propertyType"
                value={localParams.propertyType}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                {propertyTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="berRating"
                className="block text-sm font-medium text-gray-700"
              >
                BER Rating
              </label>
              <select
                id="berRating"
                name="berRating"
                value={localParams.berRating}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                {berRatings.map((rating) => (
                  <option key={rating} value={rating}>
                    {rating}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="listingIntent"
                className="block text-sm font-medium text-gray-700"
              >
                Listing Intent
              </label>
              <select
                id="listingIntent"
                name="listingIntent"
                value={localParams.listingIntent}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                {listingIntents.map((intent) => (
                  <option key={intent} value={intent}>
                    {intent === 'Any'
                      ? 'Any'
                      : intent.charAt(0).toUpperCase() + intent.slice(1)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="availableFrom"
                className="block text-sm font-medium text-gray-700"
              >
                Available From
              </label>
              <DatePicker
                id="availableFrom"
                selected={localParams.availableFrom}
                onChange={handleDateChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholderText="Select date"
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                isClearable
              />
            </div>
          </div>

          <div className="flex justify-end mt-4 space-x-2">
            <button
              type="button"
              onClick={handleClearFilters}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300"
            >
              Clear Filters
            </button>
            <button
              type="button"
              onClick={handleApplyFilters}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
            >
              Apply Filters
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
