import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  PropsWithChildren
} from 'react';
import Cookies from 'js-cookie';

interface CookieConsentSettings {
  status: ConsentStatus;
  analytics: boolean;
  functionality: boolean;
  personalization: boolean;
  advertising: boolean;
  security: boolean;
  expiryDate: string | null;
}

export type ConsentStatus = 'accepted' | 'rejected' | 'partial' | null;

interface CookieConsentContextType {
  consentStatus: ConsentStatus;
  consentSettings: CookieConsentSettings;
  updateConsentStatus: (
    status: ConsentStatus,
    settings?: Partial<CookieConsentSettings>
  ) => void;
  hasAnalyticsConsent: () => boolean;
  hasFullConsent: () => boolean;
  clearConsent: () => void;
  showConsentForm: () => void;
  hideConsentForm: () => void;
  isConsentFormVisible: boolean;
}

const COOKIE_CONSENT_NAME = 'cookie_consent_status';
const COOKIE_SETTINGS_NAME = 'cookie_consent_settings';

const defaultSettings: CookieConsentSettings = {
  status: null,
  analytics: false,
  functionality: false,
  personalization: false,
  advertising: false,
  security: true, // Always required
  expiryDate: null
};

const CookieConsentContext = createContext<
  CookieConsentContextType | undefined
>(undefined);

export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext);
  if (context === undefined) {
    throw new Error(
      'useCookieConsent must be used within a CookieConsentProvider'
    );
  }
  return context;
};

export const CookieConsentProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const [consentStatus, setConsentStatus] = useState<ConsentStatus>(null);
  const [consentSettings, setConsentSettings] =
    useState<CookieConsentSettings>(defaultSettings);
  const [isConsentFormVisible, setIsConsentFormVisible] = useState(false);

  // Load saved consent settings once on component mount
  useEffect(() => {
    const loadSavedConsent = () => {
      const storedConsent = Cookies.get(COOKIE_CONSENT_NAME) as ConsentStatus;
      const storedSettings = Cookies.get(COOKIE_SETTINGS_NAME);

      if (!storedConsent || !storedSettings) {
        setIsConsentFormVisible(true);
        return;
      }

      try {
        const settings = JSON.parse(storedSettings);
        if (settings.expiryDate && new Date(settings.expiryDate) > new Date()) {
          setConsentStatus(storedConsent);
          setConsentSettings(settings);
          setIsConsentFormVisible(false);
        } else {
          clearConsent();
        }
      } catch (error) {
        console.error('Error parsing cookie settings:', error);
        clearConsent();
      }
    };

    loadSavedConsent();
  }, []);

  // Clear all consent cookies and reset to default state
  const clearConsent = () => {
    Cookies.remove(COOKIE_CONSENT_NAME);
    Cookies.remove(COOKIE_SETTINGS_NAME);

    // Remove all non-essential cookies based on categories
    if (consentSettings.analytics) {
      // Remove analytics cookies (e.g., Google Analytics)
      Cookies.remove('_ga');
      Cookies.remove('_gid');
    }

    if (consentSettings.advertising) {
      // Remove advertising cookies (e.g., Facebook Pixel)
      Cookies.remove('_fbp');
    }

    // Reset state
    setConsentStatus(null);
    setConsentSettings(defaultSettings);
    setIsConsentFormVisible(true); // Ensure banner shows after clearing
  };

  const showConsentForm = () => {
    setIsConsentFormVisible(true);
  };

  const hideConsentForm = () => {
    setIsConsentFormVisible(false);
  };

  const updateConsentStatus = (
    status: ConsentStatus,
    settings?: Partial<CookieConsentSettings>
  ) => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365); // 1 year expiry

    const newSettings: CookieConsentSettings = {
      ...defaultSettings,
      ...settings,
      status,
      expiryDate: expiryDate.toISOString()
    };

    // Ensure all preferences are enabled if status is 'accepted'
    if (status === 'accepted') {
      newSettings.analytics = true;
      newSettings.functionality = true;
      newSettings.personalization = true;
      newSettings.advertising = true;
      newSettings.security = true;
    }

    // Update state
    setConsentStatus(status);
    setConsentSettings(newSettings);
    setIsConsentFormVisible(false);

    // Save to cookies with proper attributes for GDPR compliance
    const cookieOptions = {
      expires: 365, // 1 year
      secure: process.env.NODE_ENV === 'production', // Secure in production
      sameSite: 'strict' as const,
      path: '/'
    };

    Cookies.set(COOKIE_CONSENT_NAME, status || '', cookieOptions);
    Cookies.set(
      COOKIE_SETTINGS_NAME,
      JSON.stringify(newSettings),
      cookieOptions
    );

    // Handle cookie cleanup for rejected categories
    if (status === 'rejected' || status === 'partial') {
      if (!newSettings.analytics) {
        Cookies.remove('_ga');
        Cookies.remove('_gid');
      }
      if (!newSettings.advertising) {
        Cookies.remove('_fbp');
      }
      // Add other category-specific cookie cleanup as needed
    }
  };

  const hasAnalyticsConsent = () => {
    return consentSettings.analytics && consentStatus === 'accepted';
  };

  const hasFullConsent = () => {
    return (
      consentStatus === 'accepted' &&
      Object.entries(consentSettings).every(([key, value]) => {
        if (key === 'status' || key === 'expiryDate') return true;
        return value === true;
      })
    );
  };

  return (
    <CookieConsentContext.Provider
      value={{
        consentStatus,
        consentSettings,
        updateConsentStatus,
        hasAnalyticsConsent,
        hasFullConsent,
        clearConsent,
        showConsentForm,
        hideConsentForm,
        isConsentFormVisible
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};
