// src/pages/NotFoundPage.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import {
  ExclamationTriangleIcon,
  ArrowLeftIcon
} from '@heroicons/react/24/solid'; // Import Heroicons
import { useAnalyticsWithConsent } from '../../hooks/useAnalyticsWithConsent';

const NotFoundPage: React.FC = () => {
    const analytics = useAnalyticsWithConsent();
  
    analytics.trackPixelPageView();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-600 p-6">
      {/* Icon with Animation */}
      <ExclamationTriangleIcon className="h-24 w-24 text-white mb-6 animate-pulse" />

      {/* Main Message */}
      <h1 className="text-6xl font-extrabold text-white mb-4">404</h1>
      <p className="text-2xl text-white mb-8 text-center">
        Oops! The page you're looking for doesn't exist.
      </p>

      {/* Navigation Buttons */}
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        {/* Go to Home Button */}
        <Link
          to="/"
          className="flex items-center justify-center px-6 py-3 bg-white text-blue-600 rounded-lg shadow-md hover:bg-gray-100 transition duration-300"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          Go to Home
        </Link>

        {/* Contact Support Button */}
        <Link
          to="/contact"
          className="flex items-center justify-center px-6 py-3 bg-transparent border-2 border-white text-white rounded-lg shadow-md hover:bg-white hover:text-blue-600 transition duration-300"
        >
          <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
          Contact Support
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
