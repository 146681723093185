import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import {
  EnvelopeIcon,
  PhoneIcon,
  HomeIcon,
  BuildingOfficeIcon,
  CameraIcon
} from '@heroicons/react/24/solid';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import { signOutUser, useAuth } from '../../auth/firebaseAuth';
import { User, Landlord, Renter, PropertyAgent } from '../../types/userTypes';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import BrowseListingsPage from '../browse-listings/BrowseListingsPage';
import {
  deactivateAccount,
  deleteAccount,
  insertDocument,
  updateDocument,
  updateUserInListings
} from '../../utility/firebaseHelpers';
import { uploadFile, deleteImage } from '../../utility/firebaseStorageHelpers';
import { capitalizeWords } from '../../utility/capitalizeWords';
import { useNavigate } from 'react-router-dom';
import { useAnalyticsWithConsent } from '../../hooks/useAnalyticsWithConsent';

const ProfilePage: React.FC = () => {
  const { currentUser, userProfile, loading } = useAuth();
  const [profile, setProfile] = useState<
    User | Landlord | Renter | PropertyAgent | null
  >(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<File | null>(null);
  // Add these state variables inside your component
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const [previewPhotoUrl, setPreviewPhotoUrl] = useState<string | null>(null);
  const [isCreatingProfile, setIsCreatingProfile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
    const analytics = useAnalyticsWithConsent();
  
    analytics.trackPixelPageView();

  const [newProfile, setNewProfile] = useState<
    Partial<Landlord | Renter | PropertyAgent>
  >({
    role: 'renter',
    name: '',
    email: currentUser?.email || '',
    phone: '',
    bio: '',
    communicationPreferences: {
      emailOffers: false,
      pushNotifications: false
    },
    listingPreferences: {
      showEmail: false,
      showMobile: false
    }
  });

  useEffect(() => {
    if (userProfile) {
      setProfile(userProfile);
    } else if (currentUser) {
      setIsCreatingProfile(true);
    }
  }, [userProfile, currentUser]);

  useEffect(() => {
    return () => {
      if (previewPhotoUrl) {
        URL.revokeObjectURL(previewPhotoUrl);
      }
    };
  }, [previewPhotoUrl]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  if (!currentUser) {
    return <BrowseListingsPage />;
  }

  const handleDeactivateAccount = async () => {
    setIsProcessing(true);
    try {
      if (!currentUser?.uid) {
        throw new Error('No user ID found');
      }
      await deactivateAccount(currentUser.uid);
      signOutUser();
      navigate('/');
    } catch (error) {
      console.error('Error deactivating account:', error);
    } finally {
      setIsProcessing(false);
      setIsDeactivateModalOpen(false);
    }
  };

  const handleDeleteAccount = async () => {
    setIsProcessing(true);
    try {
      if (!currentUser?.uid) {
        throw new Error('No user ID found');
      }
      await deleteAccount(currentUser.uid);
      signOutUser();
      navigate('/');
    } catch (error) {
      console.error('Error deleting account:', error);
    } finally {
      setIsProcessing(false);
      setIsDeleteModalOpen(false);
    }
  };

  const handleSave = async () => {
    if (!profile && !isCreatingProfile) {
      console.error('Profile is not loaded and not in creation mode.');
      return;
    }

    setIsUploading(true);
    try {
      let photoUrl = profile?.photoUrl || '';

      if (selectedPhoto) {
        const path = `profile-photos/${currentUser.uid}/${selectedPhoto.name}`;
        const newPhotoUrl = await uploadFile(selectedPhoto, path);

        if (profile?.photoUrl) {
          await deleteImage(profile.photoUrl);
        }

        photoUrl = newPhotoUrl;
      }

      let updatedProfile: User;

      if (isCreatingProfile) {
        const profileData: Landlord | Renter | PropertyAgent = {
          ...newProfile,
          id: currentUser.uid,
          accountCreationDate: serverTimestamp(),
          photoUrl: photoUrl,
          listingPreferences: {
            showEmail: newProfile.listingPreferences?.showEmail || false,
            showMobile: newProfile.listingPreferences?.showMobile || false
          }
        } as Landlord | Renter | PropertyAgent;

        await insertDocument<User>(
          'users',
          profileData as User,
          currentUser.uid
        );
        updatedProfile = profileData as User;
        setProfile(profileData);
        setIsCreatingProfile(false);
      } else {
        if (profile) {
          profile.photoUrl = photoUrl;
          await updateDocument<User>('users', currentUser.uid, profile as User);
          updatedProfile = { ...profile } as User;
          setProfile({ ...profile } as User);
        } else {
          throw new Error('Profile is undefined');
        }
      }

      // Update user data in all associated listings
      await updateUserInListings(currentUser.uid, updatedProfile);

      setIsEditing(false);
    } catch (error) {
      console.error('Error saving profile:', error);
    } finally {
      setIsUploading(false);
      if (previewPhotoUrl) {
        URL.revokeObjectURL(previewPhotoUrl);
      }
      setPreviewPhotoUrl(null);
      setSelectedPhoto(null);
    }
  };

  const handleCancel = () => {
    if (previewPhotoUrl) {
      URL.revokeObjectURL(previewPhotoUrl);
    }
    setPreviewPhotoUrl(null);
    setSelectedPhoto(null);
    setIsEditing(false);
    if (isCreatingProfile) {
      setIsCreatingProfile(false);
    }
  };

  const handlePreferenceChange = (
    preference: 'emailOffers' | 'pushNotifications'
  ) => {
    if (profile) {
      setProfile({
        ...profile,
        communicationPreferences: {
          ...profile.communicationPreferences,
          [preference]: !profile.communicationPreferences[preference]
        }
      });
    }
  };

  const handleListingPreferenceChange = (
    preference: 'showEmail' | 'showMobile'
  ) => {
    if (isCreatingProfile) {
      // For new profile creation
      setNewProfile((prev) => ({
        ...prev,
        listingPreferences: {
          ...(prev.listingPreferences ?? {
            showEmail: false,
            showMobile: false
          }),
          [preference]: !(prev.listingPreferences?.[preference] ?? false)
        }
      }));
    } else if (profile) {
      // For existing profile
      setProfile({
        ...profile,
        listingPreferences: {
          ...(profile.listingPreferences ?? {
            showEmail: false,
            showMobile: false
          }),
          [preference]: !(profile.listingPreferences?.[preference] ?? false)
        }
      });
    }
  };

  const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedPhoto(file);

      if (previewPhotoUrl) {
        URL.revokeObjectURL(previewPhotoUrl);
      }

      const previewUrl = URL.createObjectURL(file);
      setPreviewPhotoUrl(previewUrl);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const renderProfileForm = () => (
    <div className="bg-white shadow-lg rounded-xl overflow-hidden p-4 md:p-8">
      <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6 text-center md:text-left">
        {isCreatingProfile ? 'Create Your Profile' : 'Edit Your Profile'}
      </h2>

      {/* Profile Photo Section */}
      <div className="mb-6 text-center md:text-left">
        <label className="block text-gray-700 font-semibold mb-2 text-sm md:text-base">
          Profile Photo
        </label>
        <div className="flex flex-col md:flex-row items-center md:items-start">
          <div className="w-32 h-32 md:w-40 md:h-40 rounded-full overflow-hidden mb-3 md:mb-0 md:mr-4">
            {previewPhotoUrl || profile?.photoUrl ? (
              <img
                src={previewPhotoUrl || profile?.photoUrl}
                alt="Profile Preview"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <CameraIcon className="w-12 h-12 text-gray-400" />
              </div>
            )}
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
            className="hidden"
            id="photo-upload"
          />
          <label
            htmlFor="photo-upload"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 cursor-pointer text-sm md:text-base mt-2 md:mt-0"
          >
            {profile?.photoUrl ? 'Change Photo' : 'Choose Photo'}
          </label>
        </div>
      </div>

      <div className="space-y-4 md:space-y-6">
        {/* Name Field */}
        <div>
          <label className="block text-gray-700 font-semibold mb-1 text-sm md:text-base">
            Name
          </label>
          <input
            type="text"
            value={isCreatingProfile ? newProfile.name : profile?.name || ''}
            onChange={(e) =>
              isCreatingProfile
                ? setNewProfile((prev) => ({ ...prev, name: e.target.value }))
                : profile && setProfile({ ...profile, name: e.target.value })
            }
            className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
            required
          />
        </div>

        {/* Email Field */}
        <div>
          <label className="block text-gray-700 font-semibold mb-1 text-sm md:text-base">
            Email
          </label>
          <input
            type="email"
            value={isCreatingProfile ? newProfile.email : profile?.email || ''}
            onChange={(e) =>
              isCreatingProfile
                ? setNewProfile((prev) => ({ ...prev, email: e.target.value }))
                : profile && setProfile({ ...profile, email: e.target.value })
            }
            className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
            required
            disabled={!isCreatingProfile}
          />
        </div>

        {/* Phone Field */}
        <div>
          <label className="block text-gray-700 font-semibold mb-1 text-sm md:text-base">
            Phone
          </label>
          <input
            type="tel"
            value={isCreatingProfile ? newProfile.phone : profile?.phone || ''}
            onChange={(e) =>
              isCreatingProfile
                ? setNewProfile((prev) => ({ ...prev, phone: e.target.value }))
                : profile && setProfile({ ...profile, phone: e.target.value })
            }
            className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
            required
          />
        </div>

        {/* Role Selection */}
        <div>
          <label className="block text-gray-700 font-semibold mb-1 text-sm md:text-base">
            Your account type
          </label>
          <select
            value={
              isCreatingProfile ? newProfile.role : profile?.role || 'renter'
            }
            onChange={(e) =>
              isCreatingProfile
                ? setNewProfile({
                    ...newProfile,
                    role: e.target.value as 'landlord' | 'renter' | 'agent'
                  })
                : profile &&
                  setProfile({
                    ...profile,
                    role: e.target.value as 'landlord' | 'renter' | 'agent'
                  } as User)
            }
            className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
            required
          >
            <option value="renter">Renter</option>
            <option value="landlord">Landlord</option>
            <option value="agent">Property Agent</option>
          </select>
        </div>

        {/* Conditional Field: Company (Landlord) */}
        {(isCreatingProfile
          ? newProfile.role === 'agent' || newProfile.role === 'landlord'
          : profile?.role === 'agent' || profile?.role === 'landlord') && (
          <div>
            <label className="block text-gray-700 font-semibold mb-1 text-sm md:text-base">
              Company
            </label>
            <input
              type="text"
              value={
                isCreatingProfile
                  ? (newProfile as Landlord).company || ''
                  : (profile as Landlord)?.company || ''
              }
              onChange={(e) =>
                isCreatingProfile
                  ? setNewProfile(
                      (prev) =>
                        ({ ...prev, company: e.target.value }) as Landlord
                    )
                  : profile &&
                    setProfile(
                      (prev) =>
                        ({ ...prev, company: e.target.value }) as Landlord
                    )
              }
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
              required
            />
          </div>
        )}

        {(isCreatingProfile
          ? newProfile.role === 'agent' || newProfile.role === 'landlord'
          : profile?.role === 'agent' || profile?.role === 'landlord') && (
          <div>
            <label className="block text-gray-700 font-semibold mb-1 text-sm md:text-base">
              PSRA Licence
            </label>
            <input
              type="text"
              value={
                isCreatingProfile
                  ? (newProfile as PropertyAgent).psraLicence || ''
                  : (profile as PropertyAgent)?.psraLicence || ''
              }
              onChange={(e) =>
                isCreatingProfile
                  ? setNewProfile(
                      (prev) =>
                        ({
                          ...prev,
                          psraLicence: e.target.value
                        }) as PropertyAgent
                    )
                  : profile &&
                    setProfile(
                      (prev) =>
                        ({
                          ...prev,
                          psraLicence: e.target.value
                        }) as PropertyAgent
                    )
              }
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
              required
            />
          </div>
        )}

        {/* Conditional Field: Preferred Location (Renter) */}
        {(isCreatingProfile
          ? newProfile.role === 'renter'
          : profile?.role === 'renter') && (
          <div>
            <label className="block text-gray-700 font-semibold mb-1 text-sm md:text-base">
              Preferred Location
            </label>
            <input
              type="text"
              value={
                isCreatingProfile
                  ? (newProfile as Renter).preferredLocation || ''
                  : (profile as Renter)?.preferredLocation || ''
              }
              onChange={(e) =>
                isCreatingProfile
                  ? setNewProfile(
                      (prev) =>
                        ({
                          ...prev,
                          preferredLocation: e.target.value
                        }) as Renter
                    )
                  : profile &&
                    setProfile(
                      (prev) =>
                        ({
                          ...prev,
                          preferredLocation: e.target.value
                        }) as Renter
                    )
              }
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
              required
            />
          </div>
        )}

        {/* Bio Field */}
        <div>
          <label className="block text-gray-700 font-semibold mb-1 text-sm md:text-base">
            Bio
          </label>
          <textarea
            value={isCreatingProfile ? newProfile.bio : profile?.bio || ''}
            onChange={(e) =>
              isCreatingProfile
                ? setNewProfile((prev) => ({ ...prev, bio: e.target.value }))
                : profile && setProfile({ ...profile, bio: e.target.value })
            }
            className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
            rows={4}
            required
          ></textarea>
        </div>

        {/* Communication Preferences */}
        <div>
          <h3 className="text-gray-700 font-semibold mb-2 text-sm md:text-base">
            Communication Preferences
          </h3>
          <label className="flex items-center space-x-3 mb-2">
            <input
              type="checkbox"
              checked={
                isCreatingProfile
                  ? newProfile.communicationPreferences?.emailOffers || false
                  : profile?.communicationPreferences.emailOffers || false
              }
              onChange={() => handlePreferenceChange('emailOffers')}
              className="form-checkbox h-5 w-5 md:h-6 md:w-6 text-blue-600"
            />
            <span className="text-gray-700 text-sm md:text-base">
              Yes, I'd like to receive emails about offers (Optional)
            </span>
          </label>
          <label className="flex items-center space-x-3">
            <input
              type="checkbox"
              checked={
                isCreatingProfile
                  ? newProfile.communicationPreferences?.pushNotifications ||
                    false
                  : profile?.communicationPreferences.pushNotifications || false
              }
              onChange={() => handlePreferenceChange('pushNotifications')}
              className="form-checkbox h-5 w-5 md:h-6 md:w-6 text-blue-600"
            />
            <span className="text-gray-700 text-sm md:text-base">
              Yes, I'd like to receive notifications about offers (Optional)
            </span>
          </label>
        </div>

        <div>
          <h3 className="text-gray-700 font-semibold mb-2 text-sm md:text-base">
            Listing Preferences
          </h3>
          <label className="flex items-center space-x-3 mb-2">
            <input
              type="checkbox"
              checked={
                isCreatingProfile
                  ? newProfile.listingPreferences?.showEmail ?? false
                  : profile?.listingPreferences?.showEmail ?? false
              }
              onChange={() => handleListingPreferenceChange('showEmail')}
              className="form-checkbox h-5 w-5 md:h-6 md:w-6 text-blue-600"
            />
            <span className="text-gray-700 text-sm md:text-base">
              Show email on property listings
            </span>
          </label>
          <label className="flex items-center space-x-3">
            <input
              type="checkbox"
              checked={
                isCreatingProfile
                  ? newProfile.listingPreferences?.showMobile ?? false
                  : profile?.listingPreferences?.showMobile ?? false
              }
              onChange={() => handleListingPreferenceChange('showMobile')}
              className="form-checkbox h-5 w-5 md:h-6 md:w-6 text-blue-600"
            />
            <span className="text-gray-700 text-sm md:text-base">
              Show mobile number on property listings
            </span>
          </label>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="mt-6 flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-4">
        <button
          onClick={handleSave}
          disabled={isUploading}
          className={`bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-all duration-300 w-full md:w-auto text-sm md:text-base ${
            isUploading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isUploading
            ? 'Saving...'
            : isCreatingProfile
              ? 'Create Profile'
              : 'Save Changes'}
        </button>
        <button
          onClick={handleCancel}
          disabled={isUploading}
          className="bg-gray-300 text-gray-700 px-6 py-3 rounded-lg hover:bg-gray-400 transition-all duration-300 w-full md:w-auto text-sm md:text-base"
        >
          Cancel
        </button>
      </div>
    </div>
  );

  const renderExistingProfile = () => {
    if (!profile) {
      return (
        <div className="text-center text-gray-500">Loading your profile...</div>
      );
    }

    return (
      <div className="bg-white shadow-lg rounded-xl overflow-hidden">
        <div className="p-4 md:p-8">
          <div className="flex flex-col md:flex-row md:items-start md:space-x-8">
            {/* Profile Photo and Name */}
            <div className="flex flex-col items-center md:items-start text-center md:text-left mb-6 md:mb-0">
              <div className="w-32 h-32 md:w-40 md:h-40 rounded-full overflow-hidden mb-4 border-4 border-white shadow-lg">
                {profile.photoUrl || currentUser.photoURL != null ? (
                  <img
                    src={profile.photoUrl || currentUser?.photoURL || ''}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-200 flex items-center justify-center text-white text-3xl md:text-5xl font-bold">
                    {profile.name.charAt(0)}
                  </div>
                )}
              </div>
              <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-1">
                {capitalizeWords(profile.name)}
              </h2>
              <p className="text-gray-500 text-sm">
                Member since{' '}
                {profile.accountCreationDate instanceof Timestamp
                  ? profile.accountCreationDate.toDate().toLocaleDateString()
                  : 'N/A'}
              </p>
            </div>

            {/* Contact Information and Bio */}
            <div className="flex-grow">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div>
                  <label className="block text-gray-600 mb-1 text-sm">
                    Email
                  </label>
                  <p className="flex items-center text-gray-700 text-sm md:text-base">
                    <EnvelopeIcon className="w-5 h-5 mr-2 text-gray-400" />
                    {profile.email}
                  </p>
                </div>
                <div>
                  <label className="block text-gray-600 mb-1 text-sm">
                    Phone
                  </label>
                  <p className="flex items-center text-gray-700 text-sm md:text-base">
                    <PhoneIcon className="w-5 h-5 mr-2 text-gray-400" />
                    {profile.phone}
                  </p>
                </div>
                {profile.role === 'landlord' && (
                  <div>
                    <label className="block text-gray-600 mb-1 text-sm">
                      Company
                    </label>
                    <p className="flex items-center text-gray-700 text-sm md:text-base">
                      <BuildingOfficeIcon className="w-5 h-5 mr-2 text-gray-400" />
                      {(profile as Landlord).company}
                    </p>
                  </div>
                )}
                {'psraLicence' in profile && (
                  <div>
                    <label className="block text-gray-600 mb-1 text-sm">
                      PSRA Licence
                    </label>
                    <p className="flex items-center text-gray-700 text-sm md:text-base">
                      <BuildingOfficeIcon className="w-5 h-5 mr-2 text-gray-400" />
                      {profile.psraLicence}
                    </p>
                  </div>
                )}
                {profile.role === 'renter' && (
                  <div>
                    <label className="block text-gray-600 mb-1 text-sm">
                      Preferred Location
                    </label>
                    <p className="flex items-center text-gray-700 text-sm md:text-base">
                      <HomeIcon className="w-5 h-5 mr-2 text-gray-400" />
                      {(profile as Renter).preferredLocation}
                    </p>
                  </div>
                )}
              </div>
              <div>
                <label className="block text-gray-600 mb-1 text-sm">Bio</label>
                <p className="text-gray-700 leading-relaxed text-sm md:text-base">
                  {profile.bio || 'N/A'}
                </p>
              </div>
            </div>
          </div>

          {/* Communication Preferences */}
          <div className="mt-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Keeping in touch
            </h2>
            <p className="text-gray-600 mb-4 text-sm md:text-base">
              We'd like to communicate with you about the things you care about.
              News that's important to you, updates that make your experiences
              even richer, and surveys to help us improve our service via
              emails, push notifications, and on-site messaging.
            </p>
            <div className="space-y-3">
              <label className="flex items-center space-x-3">
                <input
                  disabled
                  type="checkbox"
                  checked={profile.communicationPreferences.emailOffers}
                  onChange={() => handlePreferenceChange('emailOffers')}
                  className="form-checkbox h-5 w-5 md:h-6 md:w-6 text-blue-600"
                />
                <span className="text-gray-700 text-sm md:text-base">
                  Yes please, I'd like to receive emails about offers and
                  services (Optional)
                </span>
              </label>
              <label className="flex items-center space-x-3">
                <input
                  disabled
                  type="checkbox"
                  checked={profile.communicationPreferences.pushNotifications}
                  onChange={() => handlePreferenceChange('pushNotifications')}
                  className="form-checkbox h-5 w-5 md:h-6 md:w-6 text-blue-600"
                />
                <span className="text-gray-700 text-sm md:text-base">
                  Yes please, I'd like to receive notifications about offers and
                  services (Optional)
                </span>
              </label>
            </div>
          </div>

          <div className="mt-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Listing Preferences
            </h2>
            <p className="text-gray-600 mb-4 text-sm md:text-base">
              Choose what contact information to display on your property
              listings.
            </p>
            <div className="space-y-3">
              <label className="flex items-center space-x-3">
                <input
                  disabled
                  type="checkbox"
                  checked={profile.listingPreferences?.showEmail}
                  onChange={() => handleListingPreferenceChange('showEmail')}
                  className="form-checkbox h-5 w-5 md:h-6 md:w-6 text-blue-600"
                />
                <span className="text-gray-700 text-sm md:text-base">
                  Show email on property listings
                </span>
              </label>
              <label className="flex items-center space-x-3">
                <input
                  disabled
                  type="checkbox"
                  checked={profile.listingPreferences?.showMobile}
                  onChange={() => handleListingPreferenceChange('showMobile')}
                  className="form-checkbox h-5 w-5 md:h-6 md:w-6 text-blue-600"
                />
                <span className="text-gray-700 text-sm md:text-base">
                  Show mobile number on property listings
                </span>
              </label>
            </div>
          </div>

          <div className="mt-8 border-t pt-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Account Management
            </h2>
            <p className="text-gray-600 mb-6">
              Options to manage your RentSmart account. Please note that these
              actions cannot be undone easily.
            </p>
            <div className="space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row justify-center">
              <button
                onClick={() => setIsDeactivateModalOpen(true)}
                className="px-6 py-2 border border-yellow-500 text-yellow-600 rounded-lg hover:bg-yellow-50 transition-colors duration-300"
              >
                Deactivate Account
              </button>
              <button
                onClick={() => setIsDeleteModalOpen(true)}
                className="px-6 py-2 border border-red-500 text-red-600 rounded-lg hover:bg-red-50 transition-colors duration-300"
              >
                Delete Account Permanently
              </button>
            </div>
          </div>

          {/* Action Button */}
          <div className="mt-6 flex justify-center">
            <button
              onClick={handleEdit}
              className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-all duration-300 w-full md:w-auto text-sm md:text-base"
            >
              Edit Profile
            </button>
          </div>
        </div>
        {/* Delete Account Modal */}
        <Transition.Root show={isDeleteModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={setIsDeleteModalOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="p-6">
                      <div className="flex items-center justify-center mb-6">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100">
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                      </div>

                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold text-center text-gray-900 mb-4"
                      >
                        Delete Account Permanently
                      </Dialog.Title>

                      <p className="text-center text-gray-600 mb-4">
                        Are you absolutely sure you want to delete your account?
                        This action cannot be undone.
                      </p>

                      <div className="bg-red-50 border border-red-100 rounded-lg p-4 mb-6">
                        <p className="text-red-600 font-medium text-center mb-2">
                          The following will be permanently deleted:
                        </p>
                        <ul className="text-gray-600 space-y-2 text-center">
                          <li>Your profile and personal information</li>
                          <li>All your property listings</li>
                          <li>All messages and conversations</li>
                          <li>All saved preferences and settings</li>
                        </ul>
                      </div>

                      <div className="flex flex-col-reverse sm:flex-row sm:justify-center gap-3">
                        <button
                          type="button"
                          className="w-full sm:w-32 px-4 py-2 rounded-lg border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 font-medium focus:outline-none"
                          onClick={() => setIsDeleteModalOpen(false)}
                          disabled={isProcessing}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="w-full sm:w-32 px-4 py-2 rounded-lg bg-red-600 text-white hover:bg-red-700 font-medium focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                          onClick={handleDeleteAccount}
                          disabled={isProcessing}
                        >
                          {isProcessing ? 'Deleting...' : 'Delete'}
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Deactivate Account Modal */}
        <Transition.Root show={isDeactivateModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={setIsDeactivateModalOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="p-6">
                      <div className="flex items-center justify-center mb-6">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100">
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-yellow-600"
                            aria-hidden="true"
                          />
                        </div>
                      </div>

                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold text-center text-gray-900 mb-4"
                      >
                        Deactivate Account
                      </Dialog.Title>

                      <p className="text-center text-gray-600 mb-6">
                        Your account will be deactivated and hidden from other
                        users. You can reactivate it by logging in again. All
                        your data will be preserved but not visible to others.
                      </p>

                      <div className="flex flex-col-reverse sm:flex-row sm:justify-center gap-3">
                        <button
                          type="button"
                          className="w-full sm:w-32 px-4 py-2 rounded-lg border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 font-medium focus:outline-none"
                          onClick={() => setIsDeactivateModalOpen(false)}
                          disabled={isProcessing}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="w-full sm:w-32 px-4 py-2 rounded-lg bg-yellow-600 text-white hover:bg-yellow-700 font-medium focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                          onClick={handleDeactivateAccount}
                          disabled={isProcessing}
                        >
                          {isProcessing ? 'Processing...' : 'Deactivate'}
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl md:text-3xl font-extrabold mb-6 text-gray-800 text-center md:text-left">
        My Profile
      </h1>
      {isCreatingProfile || isEditing
        ? renderProfileForm()
        : renderExistingProfile()}
    </div>
  );
};

export default ProfilePage;
