import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Clock,
  Search,
  MessagesSquare,
  Target,
  ArrowRight,
  MessageCircle,
  LineChart,
  Camera
} from 'lucide-react';
import image17 from '../../media/images/Two_people_looking_happy_that_they_found_their_dre (1).png';
import image18 from '../../media/images/Two_people_who_look_like_happy_that_they_just_foun.png';
import { useAnalyticsWithConsent } from '../../hooks/useAnalyticsWithConsent';

const OnboardingPage = () => {
  const navigate = useNavigate();
    const analytics = useAnalyticsWithConsent();
  
    analytics.trackPixelPageView();

  const handleGetStarted = () => {
    navigate('/dashboard');
  };

  const features = [
    {
      icon: Target,
      title: 'Smart Matching',
      description:
        'Our platform helps connect you with tenants actively looking in your area and price range.'
    },
    {
      icon: Clock,
      title: 'Quick Listings',
      description:
        'List your property in minutes with our streamlined process. Most properties are rented within 72 hours.'
    },
    {
      icon: Search,
      title: 'Maximum Visibility',
      description:
        'Your property gets exposed to thousands of active home seekers in your area.'
    },
    {
      icon: MessagesSquare,
      title: 'Streamlined Communication',
      description:
        'Manage all tenant inquiries efficiently through our integrated messaging system.'
    }
  ];

  const steps = [
    {
      icon: Camera,
      title: 'Upload Photos',
      description:
        'Add high-quality photos of your property. Properties with good photos rent 2x faster.'
    },
    {
      icon: MessageCircle,
      title: 'Set Details & Price',
      description:
        'Provide property details and set your rental price. Our smart pricing tool helps you stay competitive.'
    },
    {
      icon: Search,
      title: 'Get Inquiries',
      description:
        'Receive inquiries from interested tenants and manage them all in one place.'
    },
    {
      icon: LineChart,
      title: 'Track Performance',
      description:
        "Monitor your listing's performance with detailed analytics and insights."
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-blue-800 text-white">
        <div className="max-w-7xl mx-auto px-4 py-24 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <h1 className="text-4xl font-bold sm:text-5xl md:text-6xl mb-6">
                List Your Property with Confidence – It’s Completely Free!
              </h1>
              <p className="text-xl md:text-2xl text-blue-100 max-w-3xl">
                Join thousands of successful landlords who trust RentSmart to
                find quality tenants quickly and efficiently.
              </p>
              <div className="mt-8 flex space-x-4">
                <button
                  onClick={handleGetStarted}
                  className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold text-lg hover:bg-blue-50 transition-all shadow-lg hover:shadow-xl flex items-center"
                >
                  List Your Property <ArrowRight className="ml-2" size={20} />
                </button>
              </div>
            </div>
            <div className="hidden lg:block">
              <img
                src={image17} // Replace with your actual image
                alt="Happy landlords and tenants"
                className="rounded-lg shadow-2xl transform -rotate-2 hover:rotate-0 transition-transform duration-300"
              />
            </div>
          </div>
        </div>
        {/* Decorative bottom curve */}
        <div className="absolute bottom-0 w-full">
          <svg
            viewBox="0 0 1440 120"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0,64L80,69.3C160,75,320,85,480,80C640,75,800,53,960,48C1120,43,1280,53,1360,58.7L1440,64L1440,120L1360,120C1280,120,1120,120,960,120C800,120,640,120,480,120C320,120,160,120,80,120L0,120Z"></path>
          </svg>
        </div>
      </div>

      {/* Success Stories Section with Image */}
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div className="order-2 lg:order-1">
            <img
              src={image18} // Replace with your actual image
              alt="Happy RentSmart users"
              className="rounded-lg shadow-xl"
            />
          </div>
          <div className="order-1 lg:order-2">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              Join Our Success Stories
            </h2>
            <p className="text-lg text-gray-600 mb-6">
              Our platform has helped thousands of landlords find great tenants
              and manage their properties efficiently.
            </p>
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <Clock className="text-blue-600" />
                <span className="text-gray-700">
                  Average listing time: 72 hours
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <Target className="text-blue-600" />
                <span className="text-gray-700">
                  95% match rate with quality tenants
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <MessageCircle className="text-blue-600" />
                <span className="text-gray-700">24/7 messaging support</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
          Why List for FREE with RentSmart?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-100 text-blue-600 mb-4 mx-auto">
                <feature.icon size={24} />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 text-center mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600 text-center">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Process Steps */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            List Your Property in 4 Simple Steps
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {steps.map((step, index) => (
              <div key={index} className="relative">
                <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow h-full">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-100 text-blue-600 mb-4">
                    <step.icon size={24} />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {step.title}
                  </h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
                {index < steps.length - 1 && (
                  <div className="hidden lg:block absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2">
                    <ArrowRight className="text-blue-300" size={24} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:py-24 lg:px-8">
          <div className="bg-blue-600 rounded-2xl shadow-xl overflow-hidden">
            <div className="pt-16 pb-12 px-6 sm:pt-20 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
              <div className="lg:self-center">
                <h2 className="text-3xl font-bold text-white sm:text-4xl">
                  <span className="block">
                    Ready to find your perfect tenant?
                  </span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-blue-200">
                  Get started now and join thousands of successful landlords on
                  RentSmart.
                </p>
                <div className="flex gap-4 mt-8">
                  <button
                    onClick={handleGetStarted}
                    className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold text-lg hover:bg-blue-50 transition-all shadow-lg hover:shadow-xl flex items-center"
                  >
                    Start Listing Now <ArrowRight className="ml-2" size={20} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;
