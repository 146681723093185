import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useCookieConsent } from '../../context/CookieConsentContext';
import { Link } from 'react-router-dom';

interface Props {
  onAccept: () => void;
  onReject: () => void;
}

const CookieConsentBanner: React.FC<Props> = ({ onAccept, onReject }) => {
  const { updateConsentStatus } = useCookieConsent();

  const handleAcceptAll = () => {
    console.log('Accepting cookies');
    const newSettings = {
      analytics: true,
      functionality: true,
      personalization: true,
      advertising: true,
      security: true
    };
    updateConsentStatus('accepted', newSettings);
    onAccept();
  };

  const handleRejectAll = () => {
    console.log('Rejecting cookies');
    const newSettings = {
      analytics: false,
      functionality: false,
      personalization: false,
      advertising: false,
      security: true // Always required
    };
    updateConsentStatus('rejected', newSettings);
    onReject();
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept All"
      declineButtonText="Reject All"
      style={{ background: '#2B373B' }}
      cookieName='cookie_consent_status'
      buttonStyle={{
        background: '#4CAF50',
        color: 'white',
        fontSize: '13px',
        borderRadius: '3px',
        padding: '8px 16px'
      }}
      declineButtonStyle={{
        background: '#f44336',
        color: 'white',
        fontSize: '13px',
        borderRadius: '3px',
        padding: '8px 16px'
      }}
      expires={365}
      enableDeclineButton
      onAccept={handleAcceptAll}
      onDecline={handleRejectAll}
    >
      <div className="flex flex-col gap-2">
        <p>
          We use cookies to enhance your browsing experience, serve personalized
          ads or content, and analyze our traffic. By clicking "Accept All", you
          consent to our use of cookies.
        </p>
        <div className="flex gap-4">
          <Link
            to="/cookie-preferences"
            className="text-blue-300 hover:text-blue-100 underline"
            onClick={(e) => e.stopPropagation()}
          >
            Customize Settings
          </Link>
          <Link
            to="/cookie-policy"
            className="text-blue-300 hover:text-blue-100 underline"
            onClick={(e) => e.stopPropagation()}
          >
            Cookie Policy
          </Link>
        </div>
      </div>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
