// src/pages/PrivacyPolicy.tsx

import React from 'react';
import { useAnalyticsWithConsent } from '../../hooks/useAnalyticsWithConsent';

const PrivacyPolicy: React.FC = () => {
    const analytics = useAnalyticsWithConsent();
  
    analytics.trackPixelPageView();
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-4xl font-bold mb-6">Privacy Policy</h1>

      <section className="mb-8">
        <p className="mb-4">
          At RentSmart.ie, we are committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy
          Policy outlines our practices concerning the collection, use, and
          sharing of your data when you use our website and services.
        </p>
        <p>Last updated: 02/11/2024</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          1. Information We Collect
        </h2>
        <p className="mb-4">We collect the following types of information:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Personal Information:</strong> Name, email address, phone
            number, and address.
          </li>
          <li>
            <strong>Account Information:</strong> Login credentials and profile
            details.
          </li>
          <li>
            <strong>Property Information:</strong> Details about properties
            listed or searched.
          </li>
          <li>
            <strong>Usage Data:</strong> Information about how you interact with
            our website and services.
          </li>
          <li>
            <strong>Communication Data:</strong> Content of messages sent
            through our platform.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          2. How We Use Your Information
        </h2>
        <p className="mb-4">We use your information to:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Provide and improve our services</li>
          <li>Communicate with you about our services</li>
          <li>Process transactions and bookings</li>
          <li>Personalize your experience on our platform</li>
          <li>Ensure the security and integrity of our services</li>
          <li>Comply with legal obligations</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          3. Data Sharing and Disclosure
        </h2>
        <p className="mb-4">We may share your information with:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>
            Other users as necessary for the functioning of our services (e.g.,
            landlords and tenants)
          </li>
          <li>Service providers who assist in our operations</li>
          <li>Legal authorities when required by law</li>
        </ul>
        <p>We do not sell your personal information to third parties.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
        <p className="mb-4">
          We implement appropriate technical and organizational measures to
          protect your personal information against unauthorized or unlawful
          processing, accidental loss, destruction, or damage.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Your Rights</h2>
        <p className="mb-4">Under GDPR, you have the right to:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Access your personal data</li>
          <li>Rectify inaccurate personal data</li>
          <li>Request erasure of your personal data</li>
          <li>Object to processing of your personal data</li>
          <li>Request restriction of processing your personal data</li>
          <li>Request transfer of your personal data</li>
          <li>Withdraw consent</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          6. Cookies and Tracking Technologies
        </h2>
        <p className="mb-4">
          We use cookies and similar tracking technologies to collect
          information about your browsing activities. You can manage your cookie
          preferences through your browser settings. For more information,
          please see our{' '}
          <a href="/cookie-policy" className="text-blue-500 hover:underline">
            Cookie Policy
          </a>
          .
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          7. Changes to This Policy
        </h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page and
          updating the "Last updated" date.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <p>Email: privacy@rentsmart.ie</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
