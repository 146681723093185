import React from 'react';
import { useCookieConsent } from '../../context/CookieConsentContext';
import toast from 'react-hot-toast';

const CookiePreferences: React.FC = () => {
  const { consentSettings, updateConsentStatus, clearConsent } =
    useCookieConsent();

  const preferenceItems = [
    {
      key: 'analytics',
      title: 'Analytics Cookies',
      description: 'Help us improve our website'
    },
    {
      key: 'functionality',
      title: 'Functionality Cookies',
      description: 'Enable site functionality and preferences'
    },
    {
      key: 'personalization',
      title: 'Personalization Cookies',
      description: 'Remember your preferences'
    },
    {
      key: 'advertising',
      title: 'Advertising Cookies',
      description: 'Help show you relevant ads'
    }
  ];

  const handleToggleConsent = (key: string) => {
    const currentValue = consentSettings[key as keyof typeof consentSettings];

    // Create new settings with the toggled value
    const newSettings = {
      ...consentSettings,
      [key]: !currentValue
    };

    // Determine the overall consent status
    const hasAnyConsent = Object.entries(newSettings).some(
      ([k, v]) =>
        k !== 'security' && k !== 'status' && k !== 'expiryDate' && v === true
    );

    const hasAllConsent = Object.entries(newSettings).every(
      ([k, v]) =>
        k === 'security' || k === 'status' || k === 'expiryDate' || v === true
    );

    // Update the consent status
    const newStatus = hasAllConsent
      ? 'accepted'
      : hasAnyConsent
        ? 'partial'
        : 'rejected';
    updateConsentStatus(newStatus, newSettings);
  };

  const handleSaveChanges = () => {
    // The status and settings are already saved through individual toggles
    // You could add additional confirmation or feedback here
    //alert('Your preferences have been saved successfully!');
     toast.success('Your preferences have been saved successfully');
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Cookie Preferences</h1>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">Current Settings</h2>
          <p className="text-gray-600 mb-4">
            Last updated:{' '}
            {consentSettings.expiryDate
              ? new Date(consentSettings.expiryDate).toLocaleDateString()
              : 'Not set'}
          </p>

          <div className="space-y-4">
            {preferenceItems.map(({ key, title, description }) => (
              <div
                key={key}
                className="flex justify-between items-center py-2 border-b"
              >
                <div className="flex-grow">
                  <h3 className="font-medium">{title}</h3>
                  <p className="text-sm text-gray-600">{description}</p>
                </div>
                <div className="flex items-center gap-3">
                  <span
                    className={`px-3 py-1 rounded-full text-sm font-medium ${
                      consentSettings[key as keyof typeof consentSettings]
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {consentSettings[key as keyof typeof consentSettings]
                      ? 'Enabled'
                      : 'Disabled'}
                  </span>
                  <button
                    onClick={() => handleToggleConsent(key)}
                    className="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                    style={{
                      backgroundColor: consentSettings[
                        key as keyof typeof consentSettings
                      ]
                        ? '#4CAF50'
                        : '#D1D5DB'
                    }}
                  >
                    <span
                      className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                        consentSettings[key as keyof typeof consentSettings]
                          ? 'translate-x-5'
                          : 'translate-x-0'
                      }`}
                    />
                  </button>
                </div>
              </div>
            ))}

            <div className="flex justify-between items-center py-2 border-b">
              <div>
                <h3 className="font-medium">Required Security Cookies</h3>
                <p className="text-sm text-gray-600">
                  Essential for site security
                </p>
              </div>
              <span className="px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                Always Enabled
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 justify-end">
          <button
            onClick={clearConsent}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition-colors"
          >
            Reset All Preferences
          </button>
          <button
            onClick={handleSaveChanges}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiePreferences;
